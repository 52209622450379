<template>
  <div>
    <template v-if="type == 'out'">
      <h3>{{ item.name || item.cName }}医生</h3>
      <p>医院：{{ item.hospital || item.hosptialName }}</p>
      <p>科室：{{ item.section }}</p>
      <p v-if="!this.SmartStorage.get('miniprogram')">邮箱：{{ item.email || item.inviteEmail }}</p>
    </template>
    <template v-else-if="type=='in'">
      <h3>{{ item.name || item.cName }}(员工号:{{ item.chrisID }})</h3>
      <p>部门：{{ item.dept }}</p>
      <p v-if="!this.SmartStorage.get('miniprogram')">邮箱：{{ item.email || item.inviteEmail }}</p>
      <p v-if="!this.SmartStorage.get('miniprogram')">手机号:{{ item.mobile }}</p>
    </template>
    <template v-else>
      <h3>{{ item.name || item.cName }}(CRM编号:{{ item.uci||item.accountName }})</h3>
      <p>参会人类型：{{ nameType }}</p>
      <p v-if="!this.SmartStorage.get('miniprogram')">手机号:{{ item.invitePhone }}</p>
      <p v-if="!this.SmartStorage.get('miniprogram')">邮箱：{{ item.inviteEmail }}</p>
      <p>医院/单位：{{ item.hosptialName || item.dept }}</p>
    </template>
  </div>
</template>

<script>
export default {
  props: ["type","item","nameType"],
};
</script>

<style>
</style>