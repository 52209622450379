<template>
  <mt-popup v-model="isShowPopup" position="bottom" class="popup_box">
    <div class="listBox attenderMain">
      <div v-if="choseItem && choseItem.type == 'QueryOftenAttender'">
        <iHeader
          backType="blank"
          :headStyle="
            ['msd', 'dsm'].includes($cookies.get('tenant'))
              ? {
                  background: '#fff',
                  color: '#13161B',
                }
              : {
                  background: 'var(--themeColor)',
                  color: '#fff',
                }
          "
          :backEvent="true"
          @back-event="choseAttenderGoBack()"
          :back="true"
        >
        <div slot="headText">
          <span class="head-txt">
            联系人选择
          </span>
        </div>
      </iHeader>
       
      </div>
      <div class="opSearchRow" v-else>
        <svg
          class="icon opChooseList2"
          aria-hidden="true"
          @click="choseAttenderGoBack()"
        >
          <use xlink:href="#iconfanhui" />
        </svg>
        <SearchRow
          @search-call="searchFun()"
          width="1.85rem"
          placeholder="姓名/医院名称"
          v-model="searchDate"
        ></SearchRow>
      </div>
      <div v-if="choseItem && choseItem.type == 'QueryOftenAttender'">
        <ul class="attenderBox">
          <h4>内部参会人</h4>
          <li
            v-for="(iitem, iindex) in OftenAttender.internalAttenders"
            :key="iindex"
            @click="chosePhone(iitem)"
          >
            <attenderPiece :type="'in'" :item="iitem"></attenderPiece>
          </li>
          <h4>外部参会人</h4>
          <li
            v-for="(oitem, oindex) in OftenAttender.outAttenders"
            :key="oindex"
            @click="chosePhone(oitem)"
          >
            <attenderPiece :type="'out'" :item="oitem"></attenderPiece>
          </li>
         
          <!-- <NoData v-if="!OftenAttender && loadType == '' && !isFirst"></NoData> -->
        </ul>
      </div>
      <div v-else-if="choseItem && [3, 4, 5].includes(choseItem.type)">
        <ul class="attenderBox">
          <li
            v-for="(mitem, mindex) in miceAttenders"
            :key="mindex"
            @click="chosePhone(mitem)"
          >
            <attenderPiece
              :type="'mice'"
              :nameType="choseItem.nameType"
              :item="mitem"
            ></attenderPiece>
          </li>
        </ul>
      </div>
      <ScrollBox
        v-else
        class="opOrderListScroll"
        @on-top="scrollTop"
        @on-bottom="scrollBottom"
        :loadType="loadType"
      >
        <template v-slot:scrollBox>
          <div v-if="dataList.length">
            <ul>
              <li
                v-for="(ditem, aindex) in dataList"
                :class="[flag == aindex && 'choiseLi']"
                :key="aindex"
                @click="chosePhone(ditem, aindex)"
              >
                <attenderPiece
                  :type="choseItem.type == 'OutPagingByMarker' ? 'out' : 'in'"
                  :item="ditem"
                ></attenderPiece>
              </li>
            </ul>
          </div>
          <Skeleton v-if="isFirst" :skList="skList" />
          
          
        </template>
      </ScrollBox>
       <Skeleton v-if="isFirst" :skList="skList" />
      <NoData
            v-if="noData && !isFirst&&![4,3,5].includes(choseItem.type)"
      ></NoData>
      <div v-if="noData&& !isFirst&&[4,3,5].includes(choseItem.type)" class="attenderTips">参会名单尚未完成收集或审批</div>
    </div>
  </mt-popup>
</template>

<script>
import attenderPiece from "@/views/common/platform/attenderPiece";
export default {
  data() {
    return {
      flag: -1,
      searchDate: "",
      dataList: [],
      OftenAttender: {},
      choseItem: {},
      miceAttenders: [],
      isFirst: true,
      total: 0,
      pageIndex: 1,
      pageSize: 10,
      loadType: "",
      noData:true,
      skList: [
        {
          width: "65%",
          row: 5,
        },
        {
          width: "20%",
          position: "right",
        },
        {
          width: "40%",
          inline: true,
        },
        {
          width: "50%",
        },
        {
          width: "30%",
          ml: ".1rem",
        },
        {
          width: "15%",
          inline: true,
        },
        {
          width: "100%",
          inline: true,
        },
        {
          width: "0",
          inline: true,
        },
      ],
      isShowPopup: false, // 组件的开启和关闭
      timeer: null,
    };
  },
  components: {
    attenderPiece,
  },
  watch: {
    isShowPopup() {
      if (this.isShowPopup) {
        this.setRouteListeners("choseAttenderGoBack"); //保存当前路由返回事件记录
        this.$root.$eventHub.$on("choseAttenderGoBack", (data) => {
          this.choseAttenderGoBack();
          this.$root.$eventHub.$off("choseAttenderGoBack");
        });
      } else {
        this.$emit("close"); //销毁组件
        this.removeRouteListeners("choseAttenderGoBack"); //返回删除记录
      }
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeer);
  },
  created() {},
  methods: {
    searchFun(){
      switch (this.choseItem.type) {
        case "QueryOftenAttender":
          this.QueryOftenAttender(this.choseItem.type);
          break;
        case "OutPagingByMarker":
        case "InPagingByMarker":
          this.pagingByMarker(true);
          break;
        default:
          this.QueryMiceAttendersFromCarPassenger(true);
          break;
      }
    },
    scrollTop(filter) {
      this.loadType = "top";
      this.pageIndex = 1;
      if (filter) {
        this.dataList = [];
      }
      this.pagingByMarker(true);
    },
    scrollBottom() {
      if (this.pageSize * this.pageIndex >= this.total) {
        this.loadType = "none";
      } else {
        this.loadType = "bottom";
        this.pageIndex += 1;
        this.pagingByMarker();
      }
    },
    QueryOftenAttender() {
      let params = {
        miceId: this.SmartStorage.get("proposalId"),
      };
      let _this = this;
      this.services.QueryOftenAttender(params).then((res) => {
        _this.isFirst = false;
        if (res.success) {
          _this.OftenAttender = res.content;
          _this.noData=res.content.internalAttenders.length<1&&res.content.outAttenders.length<1
        }
      });
    },
    pagingByMarker(clear) {
      let params = {
        miceId: this.SmartStorage.get("proposalId"),
        noCount: false,
        source: "",
        marker: this.searchDate || "",
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
      };
      let _this = this;
      console.log('0000',this.choseItem.type)
      this.services[this.choseItem.type](params).then((res) => {
        _this.isFirst = false;
        if (res.success) {
          if (clear == true) {
            _this.dataList = [];
          }
          let eventList = res.content.rows;
          for (let i = 0; i < eventList.length; i++) {
            _this.dataList.push(eventList[i]);
          }
          _this.total = res.content.total;
          _this.loadType = "";
          _this.noData=_this.dataList.length<1;
        }
      });
    },
    QueryMiceAttendersFromCarPassenger(clear) {
      let params = {
        miceId: this.SmartStorage.get("proposalId"),
        attenderType: this.choseItem.type,
        keywords: this.searchDate
      };
      let _this = this;
      this.services.QueryMiceAttendersFromCarPassenger(params).then((res) => {
        _this.isFirst = false;
        if (res.success) {
          _this.miceAttenders = res.content;
           _this.noData=res.content.length<1;
        }
      });
    },
    // 组件引用方式入口
    showPopup(data) {
      this.choseItem = data;
      this.isShowPopup = true;
      console.log('data====',data)
      switch (data.type) {
        case "QueryOftenAttender":
          this.QueryOftenAttender(data.type);
          break;
        case "OutPagingByMarker":
        case "InPagingByMarker":
          this.pagingByMarker(true);
          break;
        default:
          this.QueryMiceAttendersFromCarPassenger(true);
          break;
      }
      // data.type=='QueryOftenAttender'?this.QueryOftenAttender(data.type):
      // this.pagingByMarker(true);
    },
    choseAttenderGoBack() {
      this.contacts = "";
      this.miceAttenders=[];
      this.OftenAttender=[];
      this.dataList = [];
      this.isShowPopup = false;
      this.searchDate='';
    },
    // 选择电话
    chosePhone(item, index) {
      this.flag = index;
      let _this = this;
      this.timeer = setTimeout(function () {
        _this.flag = -1;
      }, 500);
      this.choseItem = {
        name: item.name || item.cName,
      };
      console.log(this.choseItem);
      this.$emit("chosePPerson", this.choseItem);
      this.choseAttenderGoBack();
    },
  },
};
</script>

<style scoped="scoped" lang="scss">
@import "@/assets/platform/index.scss";

.popup_box {
  font-size: 0.14rem;
  width: 100% !important;
  height: 100% !important;
  box-sizing: border-box !important;
  background-color: #fff !important;
}
.attenderTips{
  width: 80%;
  margin: 0.4rem auto;
}
</style>